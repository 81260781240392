import styled from "styled-components";

export const AboutMeHeaderContainer = styled.div`
  width: 100%;
  padding: 24px 48px;
  border-bottom: ${({ theme }) => `2px solid ${theme.text}`};
  box-sizing: border-box;
`;

export const AboutMeHeader = styled.h2`
  font-size: 32px;
  color: ${({ theme }) => theme.text};
  font-weight: 600;
`;

export const AboutMeText = styled.p`
  font-size: 16px;
  color: ${({ theme }) => theme.text};
  font-weight: 400;
`;

export const AboutMeTextContainer = styled.div`
  display: inline-flex;
  flex-direction: column;
  gap: 24px;
  padding: 32px 48px;
  box-sizing: border-box;
  width: 100%;

  @media (max-width: 979px) {
    padding: 32px 48px 48px 48px;
  }
`;
