import styled from "styled-components";
import { AboutMeHeader, AboutMeHeaderContainer } from "../AboutMe/styled";

export { AboutMeTextContainer as ExperienceTextContainer } from "../AboutMe/styled";

export const ExperienceContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ExperienceRow = styled.div`
  display: flex;
  gap: 12px;
`;

export const ExprerienceGroup = styled.div`
  display: inline-flex;
  flex-direction: column;
  gap: 24px;
`;

export const ExperienceGroupHeader = styled.h3`
  color: ${({ theme }) => theme.text};
  font-weight: 700;
  font-size: 20px;
`;

export const ExperienceHeaderContainer = styled(AboutMeHeaderContainer)`
  @media (max-width: 979px) {
    border-top: ${({ theme }) => `3px solid ${theme.borderTopColor}`};
  }
`;

export const ExperienceHeader = styled(AboutMeHeader)`
  color: ${({ theme }) => theme.primaryColor};
`;

const BaseHighlightText = styled.span`
  color: ${({ theme }) => theme.primaryColor};
`;

export const HighlightedSubHeaderText = styled(BaseHighlightText)`
  font-size: 20px;
`;

export const HighlightedTechText = styled(BaseHighlightText)`
  font-size: 16px;
  font-weight: 700;
`;

export const AccentText = styled.p`
  color: ${({ theme }) => theme.accentColor};
  font-size: 16px;
  font-weight: 700;
`;

export const BodyText = styled.p`
  color: ${({ theme }) => theme.text};
  font-size: 16px;
  font-weight: 400;
`;
