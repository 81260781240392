import React from "react";
import AboutMe from "./AboutMe";
import ContactMe from "./ContactMe";
import Experience from "./Experience";
import {
  DesktopContainer,
  MobileContainer,
  SideContainer,
  ExperienceContainer,
} from "./styled";

const Body = () => {
  return (
    <>
      <DesktopContainer>
        <SideContainer>
          <AboutMe />
          <ContactMe />
        </SideContainer>
        <ExperienceContainer>
          <Experience />
        </ExperienceContainer>
      </DesktopContainer>
      <MobileContainer>
        <AboutMe />
        <Experience />
        <ContactMe />
      </MobileContainer>
    </>
  );
};

export default Body;
