import styled from "styled-components";
import {
  AboutMeHeaderContainer,
  AboutMeTextContainer,
} from "../AboutMe/styled";

export { AboutMeHeader as ContactMeHeader } from "../AboutMe/styled";

export const ContactMeHeaderContainer = styled(AboutMeHeaderContainer)`
  border-top: ${({ theme }) => `3px solid ${theme.borderTopColor}`};
`;

export const ContactMeTextContainer = styled(AboutMeTextContainer)`
  padding-bottom: 60px;
`;

export const DetailsText = styled.p`
  font-size: 16px;
  font-weight: 700;
`;

export const DetailsTextContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  flex-wrap: wrap;
`;

export const ContactSectionText = styled(DetailsText)`
  color: ${({ theme }) => theme.accentColor};
`;

export const ContactLinkText = styled(DetailsText)`
  color: ${({ theme }) => theme.primaryColor};
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

export const ContactCopiedMessage = styled(ContactSectionText)`
  transition: all 0.2s linear;
  margin-left: 8px;
`;
