import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: 'Roboto-Mono';
    src: url('./assets/RobotoMono/static/RobotoMono-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'Roboto-Mono';
    src: url('./assets/RobotoMono/static/RobotoMono-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: 'Roboto-Mono';
    src: url('./assets/RobotoMono/static/RobotoMono-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
  }

  html, body, #root {
    min-height: 100vh;
  height: 100%;
}

  html, body {
    background: ${({ theme }) => theme.bodyColor};   
    padding: 0;
    margin: 0;
    transition: all 0.2s linear;
    font-family: 'Roboto Mono', monospace;
  }

  h1, h2, h3, p {
    margin: 0;
  }
`;
