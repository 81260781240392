import React, { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  ContactMeHeader,
  ContactMeHeaderContainer,
  ContactMeTextContainer,
  DetailsTextContainer,
  ContactSectionText,
  ContactLinkText,
  ContactCopiedMessage,
} from "./styled";

const copiedEmailResponse = [
  "Copied!",
  "Yup, its copied!",
  "Nice!",
  "You did it!",
  "What a pro!",
  "Wow!",
];

const contactInfo = [
  {
    section: "Email",
    name: "averybrowndev@gmail.com",
    link: "averybrowndev@gmail.com",
    type: "copy",
  },
  {
    section: "Linked In",
    name: "Avery Brown",
    link: "https://www.linkedin.com/in/avery-brown-126b55191/",
    type: "link",
  },
  {
    section: "GitHub",
    name: "AveryTheDev",
    link: "https://github.com/AveryTheDev",
    type: "link",
  },
];

const ContactMe = () => {
  const [copied, setCopied] = useState(false);
  const [randomNumber, setRandomNumber] = useState(0);

  const copyEmail = () => {
    setCopied(true);
    setRandomNumber(Math.floor(Math.random() * 6));
  };

  return (
    <>
      <ContactMeHeaderContainer>
        <ContactMeHeader>Contact Me.</ContactMeHeader>
      </ContactMeHeaderContainer>
      <ContactMeTextContainer>
        {contactInfo.map(({ section, name, link, type }) => {
          if (type === "link") {
            return (
              <DetailsTextContainer key={link}>
                <ContactSectionText>{section}: </ContactSectionText>
                <ContactLinkText as="a" href={`${link}`}>
                  {name}
                </ContactLinkText>
              </DetailsTextContainer>
            );
          }
          return (
            <DetailsTextContainer key={name}>
              <ContactSectionText>{section}: </ContactSectionText>
              <CopyToClipboard text={name} onCopy={() => copyEmail(true)}>
                <ContactLinkText>{name}</ContactLinkText>
              </CopyToClipboard>
              <ContactCopiedMessage>
                {copied && copiedEmailResponse[randomNumber]}
              </ContactCopiedMessage>
            </DetailsTextContainer>
          );
        })}
      </ContactMeTextContainer>
    </>
  );
};

export default ContactMe;
