import React from "react";
import {
  ExperienceContainer,
  ExperienceHeaderContainer,
  ExperienceTextContainer,
  ExprerienceGroup,
  ExperienceRow,
  ExperienceGroupHeader,
  ExperienceHeader,
  HighlightedSubHeaderText,
  HighlightedTechText,
  AccentText,
  BodyText,
} from "./styled";
import { experience } from "./utils";

const Experience = () => {
  return (
    <ExperienceContainer>
      <ExperienceHeaderContainer>
        <ExperienceHeader>Work Experience.</ExperienceHeader>
      </ExperienceHeaderContainer>
      <ExperienceTextContainer>
        {experience.map(({ title, place, duration, tech, role }) => (
          <ExprerienceGroup>
            <ExperienceGroupHeader>
              {title} <HighlightedSubHeaderText>at</HighlightedSubHeaderText>{" "}
              {place}
            </ExperienceGroupHeader>
            <ExperienceRow>
              <b>Duration:</b> <BodyText>{duration}</BodyText>
            </ExperienceRow>
            <ExperienceRow>
              <AccentText>Tech:</AccentText>{" "}
              <HighlightedTechText>{tech}</HighlightedTechText>
            </ExperienceRow>
            <ExperienceRow>
              <b>Role:</b> <BodyText>{role}</BodyText>
            </ExperienceRow>
          </ExprerienceGroup>
        ))}
      </ExperienceTextContainer>
    </ExperienceContainer>
  );
};

export default Experience;
