import styled from "styled-components";

export const HeaderContainer = styled.div`
  box-sizing: border-box;
  display: inline-flex;
  flex-direction: column;
  gap: 24px;
  padding: 32px 48px 60px 48px;
  width: 100%;
  border-bottom: ${({ theme }) => `3px solid ${theme.borderTopColor}`};

  @media (max-width: 979px) {
    padding-bottom: 72px;
  }
`;

export const HeaderText = styled.h1`
  font-size: 48px;
  color: ${({ theme }) => theme.text};
`;

export const NameText = styled.span`
  font-size: 48px;
  color: ${({ theme }) => theme.primaryColor};
`;
