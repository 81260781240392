import styled from "styled-components";

export const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
`;

export const DesktopContainer = styled.div`
  display: grid;
  grid-template: 1fr / 35% 65%;

  @media (max-width: 979px) {
    display: none;
  }
`;

export const MobileContainer = styled.div`
  @media (min-width: 980px) {
    display: none;
  }
`;

export const SideContainer = styled(BodyContainer)`
  width: 100%;
  border-right: ${({ theme }) => `2px solid ${theme.text}`};
`;

export const ExperienceContainer = styled.div``;
