export const experience = [
  {
    title: "Front-End Engineer I",
    place: "Coalition",
    duration: "Apr 2022 - Present",
    tech: "React, TypeScript, Jest, React Testing Library, Cypress, GraphQL, Apollo",
    role: "Front-End Engineer I for a company that serves as a cyber insurance provider.",
  },
  {
    title: "Front-End Software Engineer",
    place: "Secberus",
    duration: "Feb 2021 - Apr 2022",
    tech: "React, Redux, TypeScript, Jest, Playwright (E2E testing), Storybook",
    role: "Front-End Software Engineer for a company that serves as a real-time cloud security posture management (CSPM) & compliance reporting platform for enterprises.",
  },
  {
    title: "UI/UX Developer",
    place: "Urban SDK",
    duration: "Jan 2020 - Feb 2021",
    tech: "React, Redux, TypeScript, Jest, PostgreSQL, Figma, Data Visualization - (Deck.GL, Mapbox, ApexCharts, Recharts)",
    role: `Acted as Lead Front-End developer after being the sole Front-End Developer for 8 months. Was in charge of designing and implementing custom dashboard and data visualizations includes a variety of custom charts and maps. 
        
        Created custom map layers which includes, but not limited to, Heatmaps, H3 Hexagon Clusters, 3D Hexagon Columns, Polygons and more.
        `,
  },
];
