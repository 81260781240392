export const lightTheme = {
  bodyColor: "#FFFCFC",
  text: "#100802",
  primaryColor: "#FF5D02",
  accentColor: "#07644D",
  borderTopColor: "#100802",
  contactText: "#100802",
};

// export const darkTheme = {
//   bodyColor: "#373D45",
//   text: "#FFFFFF",
//   primaryColor: "#16D8A9",
//   accentColor: "#FFA902",
//   borderTopColor: "#FFA902",
// };
