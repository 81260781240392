import React from "react";
import {
  AboutMeHeader,
  AboutMeHeaderContainer,
  AboutMeText,
  AboutMeTextContainer,
} from "./styled";

const AboutMe = () => (
  <>
    <AboutMeHeaderContainer>
      <AboutMeHeader>About Me.</AboutMeHeader>
    </AboutMeHeaderContainer>
    <AboutMeTextContainer>
      <AboutMeText>
        I'm a <b>web developer </b>with a proven history of{" "}
        <b>self-sufficiency and high-drive</b>, having worked independently as
        the sole front-end developer for a startup in its critical early stages.
        To describe myself as adaptive and dedicated would be an understatement.
      </AboutMeText>
      <AboutMeText>
        What I love the most about being a developer is being able to bring
        other's ideas into fruition for a <b>greater purpose</b>.
      </AboutMeText>
      <AboutMeText>
        When I'm not working, I'm in the kitchen experimenting, playing video
        games, <b>petting my dog</b>, finding a kayak to rent, or crafting
        something to release my creative energy.
      </AboutMeText>
    </AboutMeTextContainer>
  </>
);

export default AboutMe;
