import React from "react";
import { HeaderContainer, HeaderText, NameText } from "./styled";

const Header = () => {
  return (
    <>
      <HeaderContainer>
        <HeaderText>Hey!</HeaderText>
        <HeaderText>
          <>
            I'm <NameText>Avery Brown</NameText>.
          </>
        </HeaderText>
      </HeaderContainer>
    </>
  );
};

export default Header;
