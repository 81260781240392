import React from "react";
import { ThemeProvider } from "styled-components";
import { AppContainer } from "./App.styled";
import { lightTheme } from "./styles/theme";
import { GlobalStyles } from "./styles/globalStyle";
import { Header, Body } from "./components";

const App = () => {
  return (
    <ThemeProvider theme={lightTheme}>
      <AppContainer>
        <GlobalStyles />
        <Header />
        <Body />
      </AppContainer>
    </ThemeProvider>
  );
};

export default App;
